
@font-face {
  font-family: 'Sui-Generis';
  src: url('./assets/fonts/NeueHaasDisplayBold.ttf') format('truetype');
}

@font-face {
  font-family: 'LinLibertine';
  src: url('./assets/fonts/NeueHaasDisplayMediu.ttf') format('truetype');
}

@font-face {
  font-family: 'GrandHotel';
  src: url('./assets/fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}




nav a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;

}
nav a:hover {
  text-decoration: underline;
}


@media (min-width: 1024px) {

  * {
    margin: 0;
    padding: 30px 30px;
    box-sizing: border-box;

  }

}

@media ((min-width: 501px) and (max-width: 1023px)) {

  * {
    margin: 0;
    padding: 5px 20px;
    box-sizing: border-box;
  }

}

@media (min-width: 1700px) {

  * {
    margin: 0;
    padding: 30px 60px;
  }

}



@media screen and (max-width: 500px) {

  * {
    margin: 0;
    padding: 3px 3px;
    box-sizing: border-box;
  }

  html, body {
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
    
  }

}